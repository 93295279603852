<template>
  <b10-base>
    <v-row
      class="justify-center"
    >
      <v-switch
        v-if="firmarParteVisible && variosFirmar"
        v-model="form.firmarParte"
        label="Parte"
        :rules="firmarRule()"
      />
      <v-switch
        v-if="firmarChecklistVisible && variosFirmar"
        v-model="form.firmarChecklist"
        :rules="firmarRule()"
        class="ml-4"
        label="Checklist"
      />
    </v-row>
    <b10-textarea
      v-if="form.firmarParte"
      v-model="form.comentario_final_cliente"
      label="Comentario final del cliente"
    />
    <v-text-field
      v-model="form.firmante"
      clearable
      label="Firmado por"
      :rules="formRules.firmante"
    />
    <v-text-field
      v-model="form.dni_firmante"
      clearable
      label="NIF del firmante"
      :rules="formRules.dni_firmante"
      :messages="messagesNIF"
    />
    <v-text-field
      v-if="form.firmarChecklist"
      v-model="form.firmante_cargo"
      clearable
      label="Cargo del firmante"
    />
    <v-row
      class="justify-center"
    >
      <b10-rate
        v-if="form.firmarParte"
        image-extension="png"
        class="mt-2 mb-4"
        @select-icon="selectNivelSatisfaccion"
      />
    </v-row>
    <v-card
      flat
    >
      <div
        class="signature-container"
      >
        <!-- para validar formRules.firma  -->
        <v-text-field
          v-show="false"
          v-model="form.firma"
          :rules="formRules.firma"
        />
        <vue-signature-pad
          ref="signaturePad"
          class="signature"
          width="100%"
          height="250px"
          :options="{ onEnd: saveFirma }"
        />
        <p
          class="signature-text"
        >
          Firma aquí
        </p>
      </div>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          color="secondary"
          @click="clearFirma"
        >
          <v-icon
            left
          >
            {{ $vuetify.icons.values.erase }}
          </v-icon> Borrar firma
        </v-btn>
      </v-card-actions>
    </v-card>
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './ParteFirmarFormData'
import { isValidNIF } from '@/utils/validate'
import { formatBase64 } from '@/utils/crypto'
import { CHECKLIST, PARTE_TRABAJO } from '@/utils/consts'

export default {
  mixins: [formMixin],
  data () {
    return {
      form: {
        firmarParte: false,
        firmarChecklist: false,
        comentario_final_cliente: null,
        firmante: null,
        dni_firmante: null,
        firmante_cargo: null,
        idnivel_satisfaccion: null,
        firma: null,
      },
      formRules: {
        firmante: [
          v => !!v || 'Campo requerido'
        ],
        dni_firmante: [
          v => !!v || 'Campo requerido'
        ],
        firma: [
          v => !!v || 'Campo requerido'
        ],
      },
      firmarParteVisible: false,
      firmarChecklistVisible: false,
    }
  },
  computed: {
    messagesNIF () {
      if (!this.form.dni_firmante || isValidNIF(this.form.dni_firmante)) {
        return []
      } else {
        return ['El formato del NIF no es válido']
      }
    },
    variosFirmar () {
      let cantFirmar = 0
      if (this.firmarParteVisible) {
        cantFirmar++
      }
      if (this.firmarChecklistVisible) {
        cantFirmar++
      }
      return cantFirmar > 1
    },
  },
  async created () {
    await this.loadForm()
  },
  methods: {
    async loadFormData (id) {
      const formData = await Data.selectFormData(this, id)
      this.$set(this.form, 'comentario_final_cliente', formData.parte_trabajo.comentario_final_cliente)
      this.firmarParteVisible = (
        !formData.parte_trabajo.firma &&
        formData.parte_trabajo.estado === PARTE_TRABAJO.estados.finalizado
      )
      this.firmarChecklistVisible = (
        formData.checklist_ot.idchecklist_ot &&
        !formData.checklist_ot.firma &&
        formData.checklist_ot.estado === CHECKLIST.estados.finalizado
      )
      this.$set(this.form, 'firmarParte', this.firmarParteVisible)
      this.$set(this.form, 'firmarChecklist', this.firmarChecklistVisible)
    },
    firmarRule () {
      // algo debe firmar
      if (!this.form.firmarParte && !this.form.firmarChecklist) {
        return [false]
      }
    },
    selectNivelSatisfaccion (value) {
      this.$set(this.form, 'idnivel_satisfaccion', value)
    },
    saveFirma () {
      const { data } = this.$refs.signaturePad.saveSignature()
      this.$set(this.form, 'firma', formatBase64(data))
    },
    clearFirma () {
      this.$refs.signaturePad.clearSignature()
      this.$set(this.form, 'firma', null)
    },
    changeNIF (value) {
      if (value) {
        this.$set(this.form, 'dni_firmante', value.toUpperCase())
      }
    },
  }
}
</script>

<style scoped>
.signature-container {
  padding: 10px;
  border: 2px dotted var(--v-grey-base);
}
.signature-text {
  margin-bottom: 0;
  text-align: center;
}
</style>
