export default {
  async selectFormData (Vue, id) {
    const tables = Vue.$offline.db.tables
    return (await Vue.$offline.db
      .select()
      .from(tables.parte_trabajo)
      .leftOuterJoin(
        tables.checklist_ot,
        tables.parte_trabajo.idorden_trabajo.eq(tables.checklist_ot.idorden_trabajo)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.parte_trabajo.idparte_trabajo.eq(id),
          Vue.$offline.db.op.or(
            tables.checklist_ot.estado.gt(0),
            tables.checklist_ot.idchecklist_ot.isNull(),
          )
        )
      )
      .exec()
    )[0]
  },
}
